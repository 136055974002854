/*
Thin            100     
Extra Light     200
Light           300
Regular         400
Medium          500
Semi-Bold       600
Bold            700
Extra-Bold      800
Black           900
*/
@font-face {
  font-family: 'Inter';
  src: url('./Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('./Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

